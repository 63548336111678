import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class TabsTwo extends Component {
  render() {
    let tab6 = "Crypto",
      tab1 = "Books",
      tab2 = "Blogs",
      tab3 = "Music",
      tab4 = "Preprints",
      tab5 = "Education";
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                    <Tab>{tab4}</Tab>
                    <Tab>{tab5}</Tab>
                    <Tab>{tab6}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <p>
                        I've written a few books over the years. Some I haven't
                        released, but they still hold a dear place in my heart.
                        Each one has allowed me to understand myself better,
                        even though probably three people read either of them.{" "}
                      </p>
                      <ul>
                        <li>
                          <a href="https://narcism.gumroad.com/l/InPres">
                            In Principio Res: Consciousness Explained - 2014{" "}
                          </a>
                          <p>
                            A non-fiction book that was written during a time of
                            deep contemplation about life, existence, and
                            meaning....and you can tell.{" "}
                            <span role="img" aria-label="smiley">
                              😃
                            </span>
                          </p>
                        </li>
                        <li>
                          <a href="https://www.amazon.co.uk/dp/B00X32K778/ref=cm_sw_em_r_mt_dp_5817CHZMB0JXG547AJ1E">
                            The Juxtaposition of Organisms - 2015{" "}
                          </a>
                          <p>
                            A non-fiction book that attempted to provide a brief
                            summary of In Principio Res, to condense its
                            information in a slightly more digestible form.
                          </p>
                        </li>
                        <li>
                          <a href="https://www.amazon.co.uk/dp/B01C6VM0WQ/ref=cm_sw_em_r_mt_dp_RH2EV9GEM3RQGEJ88DRG">
                            The Meaning of Life - 2016{" "}
                          </a>
                          <p>
                            When I wrote this book, which I agree bears a modest
                            title, I was thinking that I wanted to make a
                            "calling card book", something that I could give to
                            someone to provide a simple summary on my thinking
                            in regards to "the big question", that they could
                            read in an hour or less. I had a chance to think
                            about this more over time and although my conclusion
                            has slightly changed, the basis of it is still the
                            same as in this book.
                          </p>
                        </li>
                        <li>
                          <a href="https://multizoa.com/understanding-our-whole/">
                            Understanding our Whole: A humorous but honest
                            attempt at redefining our understanding of human
                            society - 2018{" "}
                          </a>
                          <p>
                            You can tell that I wrote this book during my Uni
                            years. Possibly my favorite of all of my books, if
                            you want to read a book of mine, read this. It is
                            weird and tries to be funny, and even if it may fail
                            sometimes, it is still not boring and dry while
                            painting its ideas, which was the aim. Also, it's
                            free to read on{" "}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                display: "inline-block",
                              }}
                              href="https://multizoa.com/understanding-our-whole/"
                            >
                              multizoa.com
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a href="https://medium.com/@narcissus">
                            Narcis Marincat' Medium - 2019 - Present{" "}
                          </a>
                          <p>
                            Medium blog under my real name, that contains
                            various ramblings and what's beneath...
                          </p>
                        </li>
                        <li>
                          <a href="https://medium.com/is-consciousness">
                            Is Consciousness... - 2020
                          </a>
                          <p>
                            Medium publication that talks about consciousness,
                            written under my real name above.
                          </p>
                        </li>
                        <li>
                          <a href="https://medium.com/@tokenosopher">
                            Tokenosopher's Medium - 2021- Present
                          </a>
                          <p>Medium blog under my crypto pen name.</p>
                        </li>
                        <li>
                          <a href="https://medium.com/sacred-coins">
                            Sacred Coins - 2021- Present
                          </a>
                          <p>
                            Medium publication that posts updates to the Sacred
                            Coin Protocol.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <p>
                        One of my favorite ways to destress is to make music.
                        Whether it's spinning a Deep House set in a club, at
                        home or producing a new song, music has been an
                        incredible outlet.
                      </p>
                      <p className="mb-5">
                        I have had a number of "stage names" over the years -
                        NarcisM, TetreN...But I've settled with Zero Brainy,
                        because I tend to apply thinking to everything, whereas
                        in music I just want to feel. To make it all about the
                        music. Zero Brainy perfectly illustrates how cerebral I
                        want to be when I work on a song, or a set.
                      </p>
                      <ul>
                        <li className="mb-7">
                          <a href="https://soundcloud.com/zero_brainy">
                            Zero Brainy - Soundcloud Account
                          </a>
                          <p className="mt-0">
                            The main hub for sharing my beats. I sometimes don't
                            pay for the account, in which case not all of my
                            music gets displayed, but then when I want to add
                            something I pay for it again, so if you don't see
                            much content, that's why.
                          </p>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/channel/UC8giM2H1bWKZx8SYOWOvKow">
                            Zero Brainy - Youtube Account
                          </a>
                          <p className="mt-0">
                            Sometimes, when I want to go crazy, I make a video
                            for a mashup or a remix.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <p className="mb-5">
                        Potential journal papers, preprinted unless stated
                        otherwise.
                      </p>
                      <ul>
                        <li className="mb-7">
                          <a href="10.32942/osf.io/tsdek">
                            Is Human Society an Organism Made of Many Animals? -
                            2020
                          </a>
                          <p className="mt-0">
                            A paper that addresses the idea that human society
                            is an organism made of many animals, i.e. a multizoa
                            organism.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li className="mb-7">
                          <a href="/">
                            MSc in Computer Science
                            <span> - University College London </span>
                          </a>{" "}
                          Distinction - 2021
                          <p className="mt-3 mb-0">
                            <span className="text-underline">
                              Talk-worthy Modules:
                            </span>
                          </p>
                          <p className="mt-0">
                            Algorithms, Database and Information Management,
                            Architecture and Operating Systems, App Engineering,
                            Foundations of Machine Learning, Software
                            Engineering, Entrepeneurship
                          </p>
                          <p className="mb-5">
                            <span className="text-underline">
                              Dissertation:
                            </span>{" "}
                            Built an Application in VR to produce the Overview
                            Effect and ran an experiment with it.
                          </p>
                        </li>
                        <li>
                          <a href="/">
                            BSc in Pyschology and Neuroscience{" "}
                            <span> Royal Holloway, University of London</span>
                          </a>{" "}
                          First Class - 2020
                          <p className="mt-3 mb-0">
                            <span className="text-underline">
                              Talk-worthy Modules:
                            </span>
                          </p>
                          <p className="mt-0">
                            Statistics, Cognitive Psychology, Social Psychology,
                            Conceptual Issues in Psychology, Brain and
                            Behaviour, Methods in Cognitive Neuroscience, Real
                            World Quantitative Techniques
                          </p>
                          <p>
                            <span className="text-underline">
                              Dissertation:
                            </span>{" "}
                            Behaviour Design Techniques used in Social
                            Networking Sites to Increase User Time Spent
                          </p>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a href="https://thesacred.xyz">
                            Sacred Y Protocol - Co-Founder & CTO
                          </a>
                          <p>
                            A cryptocurrency protocol designed to channel the
                            immense resources and creativity of the blockchain
                            into solving some of the world's problems
                          </p>
                        </li>
                        <li>
                          <a href="https://dex.thesacred.xyz">
                            The Sacred Dex - Fullstack Developer<span></span>
                          </a>
                          <p>
                            A decentralized Exchange that aims to facilitate the
                            Sacred Y Protocol's Mission statement by allowing
                            for the listing of Sacred Coins such as the
                            Gratitude Coin, a cryptocurrency whose main
                            intention is to encourage the practice of gratitude.
                            🙏
                          </p>
                        </li>
                        <li>
                          <a href="https://tokenosopher.github.io/better_times_token_landing/">
                            The Better Times Token - Sole Developer
                          </a>
                          <p>
                            A cryptocurrency whose unique properties and staking
                            mechanisms are designed to help eliminate poverty
                            from the world as its value increases.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
