import React, { Component } from "react";
// import '../../index.scss'

class ContactThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rnName: "",
      rnEmail: "",
      rnSubject: "",
      rnMessage: "",

      showSuccess: false,
      showFail: false,

      btnText: "SUBMIT",
      btnDisabled: false,

      errorMessage: "",
    };
  }

  hideModal = () => {
    this.setState({ showSuccess: false });
    this.setState({ showFail: false });
  };

  disableButton = () => {
    this.setState({ btnText: "LOADING" });
    this.setState({ btnDisabled: true });
  };

  enableButton = () => {
    this.setState({ btnText: "SUBMIT" });
    this.setState({ btnDisabled: false });
  };

  submitForm = async (e) => {
    if (this.state.rnName === "" || this.state.rnEmail === "") {
      console.log("email missing");
    } else {
      e.preventDefault();
      this.disableButton();
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: this.state.rnName,
          email: this.state.rnEmail,
          message: this.state.rnMessage,
        }),
      };
      let a_name = await fetch(
        "https://dev--personalsubscribe.sacredcoinprotocol.autocode.gg/",
        requestOptions
      );

      let json_result = await a_name.json();
      console.log(json_result);
      if (json_result.status === "subscribed") {
        this.setState({
          showSuccess: true,
          rnName: "",
          rnEmail: "",
          rnMessage: "",
        });
      } else {
        //creating a variable that contains the error:
        let thisError = json_result.detail;

        //creating a nice message for the modal if the email already is in the database:
        if (thisError.includes(" is already a list member")) {
          this.setState({ errorMessage: "You are already subscribed!" });
        } else {
          this.setState({ errorMessage: thisError });
        }
        this.setState({ showFail: true });
      }
    }
    this.enableButton();
  };

  Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show
      ? "modal display-block"
      : "modal display-none";
    return (
      <div className={showHideClassName}>
        <section className="modal-main">
          {children}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              className="rn-button-style--2 btn-solid mb-3"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </section>
      </div>
    );
  };

  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50 mt--50">
                <h2 className="title">{this.props.contactTitle}</h2>

                <p className="description">
                  If you want to contact me, you can do so via{" "}
                  <a href="https://twitter.com/tokenosopher"> Twitter </a> or{" "}
                  <a href="https://www.linkedin.com/in/narcis-marincat/">
                    LinkedIn.
                  </a>
                </p>
                <p className="description">
                  You can also send me an email. The address is the same as the
                  website address, but with an @ instead of a dot between my
                  first and last name.
                </p>
              </div>
              {/* <div className="form-wrapper">
                                <form>
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="Your Name *"
                                            required
                                            disabled={this.state.btnDisabled}
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="email"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder="Your email *"
                                            required
                                            disabled={this.state.btnDisabled}
                                        />
                                    </label>

                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="(Optional) You can write me a message when you subscribe."
                                            disabled={this.state.btnDisabled}
                                        />
                                    </label>
                                    <button className={!this.state.btnDisabled ? "rn-button-style--2 btn-solid" : "rn-button-style--2 disabled"} type="submit" value="submit" name="submit" id="mc-embedded-subscribe" onClick={this.submitForm} disabled={this.state.btnDisabled}>{this.state.btnText}</button>
                                </form>
                            </div> */}
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img src={`${this.props.contactImages}`} alt="Narcis" />
              </div>
            </div>
          </div>
        </div>

        {/*success modal:*/}
        <this.Modal show={this.state.showSuccess} handleClose={this.hideModal}>
          <h3 className="center-align mt-3">Subscribed!</h3>
          <hr className="white mb-4" />
          <h5 className="mb-5 ml-3">Thank you for signing up.</h5>
        </this.Modal>

        {/*fail modal:*/}
        <this.Modal show={this.state.showFail} handleClose={this.hideModal}>
          <h3 className="center-align mt-3">Oops!</h3>
          <hr className="white mb-4" />
          <h5 className="mb-3 ml-3">Something went wrong:</h5>
          <h5 className="mb-5 ml-3">{this.state.errorMessage}</h5>
        </this.Modal>
      </div>
    );
  }
}

export default ContactThree;
