// React Required
import React, {Component} from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';
//
// Home layout

// Dark Home Layout
import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout


// Blocks Layout
import * as serviceWorker from './serviceWorker';
import {Helmet, HelmetProvider} from "react-helmet-async";

const darkPortfolioLanding =  <DarkPortfolioLanding />;

class Root extends Component{
    render(){
        return(
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Narcis Marincat</title>
                    <meta name="description" content="The home of Narcis Marincat" />
                </Helmet>
                {darkPortfolioLanding}
            </HelmetProvider>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();