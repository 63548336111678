import React, { Component } from "react";

const PortfolioListContent = [
  {
    image: "image-4",
    category: "Web App as an Art Installation",
    title: "consciousness.social",
    link: "https://consciousness.social",
  },
  {
    image: "image-1",
    category: "Social",
    title: "Multizoa.com",
    link: "https://multizoa.com",
  },
  {
    image: "image-2",
    category: "Crypto",
    title: "Sacred Y Protocol",
    link: "https://thesacred.xyz",
  },
  {
    image: "image-3",
    category: "Crypto",
    title: "Sacred Dex",
    link: "https://dex.thesacred.xyz/",
  },
];

class PortfolioList extends Component {
  render() {
    const { column, styevariation } = this.props;
    const list = PortfolioListContent.slice(0, this.props.item);
    return (
      <React.Fragment>
        {list.map((value, index) => (
          <div className={`${column}`} key={index}>
            <div className={`portfolio ${styevariation}`}>
              <div className="thumbnail-inner">
                <div className={`thumbnail ${value.image}`}></div>
                <div className={`bg-blr-image ${value.image}`}></div>
              </div>
              <div className="content">
                <div className="inner">
                  <p>{value.category}</p>
                  <h4 className="portfolio-title">
                    <a href={value.link}>{value.title}</a>
                  </h4>
                  <div className="portfolio-button">
                    <a className="rn-btn" href={value.link}>
                      Visit
                    </a>
                  </div>
                </div>
              </div>
              {/*<Link className="link-overlay" to="/portfolio-details"></Link>*/}
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default PortfolioList;
